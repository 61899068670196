import styled from "styled-components"

export const ContactContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 6rem 2rem 6rem 2rem;
  background-color: #fff1ed;

  .mobile-title {
    display: inherit;
    text-align: center;
    text-transform: uppercase;
    font-weight: 400;
  }

  @media (min-width: ${props => props.theme.sizes.xmobile}) {
    .mobile-title {
      display: none;
    }
  }
`

export const ContentBody = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  position: relative;

  svg {
    max-width: 120px;
    max-height: 254px;
    margin-top: 1rem;

    #inner-color {
      transition-delay: ${props => (props.isColor ? "1.5s" : "none")};
      fill: ${props => (props.isColor ? "" : "#fff")};
    }
  }

  @media (min-width: ${props => props.theme.sizes.xmobile}) {
    flex-direction: row;
    align-items: center;
    svg {
      margin-top: 0rem;
    }
  }
`

export const Body = styled.div`
  margin-top: 1rem;
  text-align: right;

  @media (min-width: ${props => props.theme.sizes.xmobile}) {
    text-align: left !important;
  }
`

export const BodyText = styled.div`
  max-width: 500px;
  justify-content: center;
  text-align: right;
  margin-bottom: 30px;
  font-weight: 300;

  p {
    margin-bottom: 0rem;
  }

  .desktop-title {
    display: none;
  }

  @media (min-width: ${props => props.theme.sizes.xmobile}) {
    text-align: left !important;
    .desktop-title {
      display: flex;
      text-transform: uppercase;
      font-weight: 400;
    }
  }
`

export const Email = styled.div`
  display: flex;
  justify-content: flex-end;
  flex-wrap: wrap;
  #check-circle {
    width: 20px;
    margin-right: 8px !important;
    margin-left: 8px !important;
    margin-top: 0px;
    cursor: pointer;
    fill: "";
  }
  #copy {
    width: 20px;
    margin-right: 8px !important;
    margin-left: 8px !important;
    margin-top: 0px;
    cursor: pointer;
    fill: ${props => (props.isColor ? "" : "#fff")};
    :hover {
      fill: #f3b0c345;
    }
  }
  align-items: center;
  a {
    color: ${props => props.theme.colors.black};
    word-wrap: anywhere;
  }
  @media (min-width: ${props => props.theme.sizes.xmobile}) {
    justify-content: left !important;
  }
`

export const EmailButton = styled.a`
  border-radius: 4px;
  border: solid 1px ${props => props.theme.colors.primary};
  color: ${props => props.theme.colors.primary};
  background-color: ${props => (props.isColor ? "" : "#fff")};
  padding: 6px;
  text-transform: uppercase;

  &:hover {
    background-color: #f3b0c345 !important;
    text-transform: uppercase;
  }
`
export const ContactImgContainer = styled.div`
  position: relative;
  padding-right: 1rem;
`
