import React, { useState } from "react"

import { graphql, useStaticQuery } from "gatsby"

import CodeIcon from "../../images/icons/code.svg"
import ExternalLinkIcon from "../../images/icons/external-link.svg"
import DeskImg from "../../images/works.svg"

import styled from "styled-components"

const WorksContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 4rem 2rem;
  min-height: inherit;
  background-color: #ebf4ff;

  h3 {
    padding: 0.5rem;
    text-align: center;
    font-weight: 400;
  }

  .work-body {
    display: flex;
    flex-direction: column;
    text-align: left;
    justify-content: space-between;
    width: 100%;
  }

  @media (min-width: ${props => props.theme.sizes.mobile}) {
    width: 100%;
    padding: 6rem 4rem;

    .work-body {
      display: flex;
      flex-direction: column-reverse;
      align-items: center;
      text-align: left;
      justify-content: space-between;
      max-width: 800px;
    }
  }

  @media (min-width: ${props => props.theme.sizes.tablet}) {
    min-height: 100vh;

    .work-body {
      display: flex;
      flex-direction: row;
      align-items: center;
      text-align: left;
      justify-content: space-between;
    }
  }
`

export const Underline = styled.span`
  padding: 2px 0px;
  cursor: pointer;
  font-weight: ${props => (props.selected ? "400" : "300")};
  background-size: ${props =>
    props.selected ? "0% 4px, 100% 4px" : "100% 4px, 0% 4px"};
  & {
    position: relative;
    padding-bottom: 0.4rem;
    text-decoration: none;
    background-image: linear-gradient(#edf5ff, #edf5ff),
      linear-gradient(${props => props.color}, ${props => props.color});
    background-position: 100% 100%, 0 100%;
    background-repeat: no-repeat;
  }

  &:hover {
    animation: ${props => (props.selected ? "none" : "underline 1s forwards")};
  }

  @keyframes underlinee {
    0% {
      background-size: 100% 4px, 0 4px;
    }
    100% {
      background-size: 0 4px, 100% 4px;
    }
  }

  @media (min-width: ${props => props.theme.sizes.mobile}) {
    margin-bottom: 0.5rem;
    &:hover {
    }
  }

  @media (min-width: ${props => props.theme.sizes.tablet}) {
    margin-bottom: 0rem;
    &:hover {
    }
  }
`

const WorkInfoContainer = styled.div`
  background-color: #d8e9ff;
  padding: 1rem;
  margin-top: 0.5rem;
  border-radius: 6px;
  font-weight: 300;
  max-width: inherit;
  width: 100%;
  margin-left: 0rem;

  @media (min-width: ${props => props.theme.sizes.mobile}) {
    margin-top: 0rem;
    max-width: 350px;
    margin-left: 1rem;
  }
  @media (min-width: ${props => props.theme.sizes.tablet}) {
    margin-top: 0.5rem;
    max-width: 350px;
    margin-left: 0rem;
  }

  .title {
    display: flex;
    justify-content: space-between;
    font-weight: 400;
  }
  .source {
    display: flex;
    svg {
      margin: 0rem 0.25rem;
    }
  }
  .description {
    font-style: italic;
    text-align: left;
  }
  .technologies {
    margin-top: 0.5rem !important;
    text-align: left;
    display: flex;
    flex-direction: column;
    margin: auto;
  }

  a:hover {
    opacity: 0.5;
  }
`

const WorkItem = styled.div`
  padding-bottom: 0.5rem;
  span {
    &.bullet {
      font-size: 14px !important;
      line-height: 1.1rem;
      padding-right: 0.5rem;
    }
  }
`

const WorkContent = styled.main`
  display: flex;
  flex-direction: column;
  @media (min-width: ${props => props.theme.sizes.mobile}) {
    flex-direction: row;
    text-align: left;
    justify-content: space-between;
    width: 100%;
    max-width: 600px;
  }

  @media (min-width: ${props => props.theme.sizes.tablet}) {
    flex-direction: column;
    text-align: left;
    justify-content: space-between;
    width: 100%;
    max-width: 600px;
    height: 340px;
  }
`

const WorkImg = styled.div`
  display: none;
  position: relative;

  .main {
    width: 500px;

    #heat-1,
    #heat-3 {
      animation: steam1 2s linear;
    }

    @keyframes steam1 {
      0% {
        transform: translateY(0);
        opacity: 1;
        filter: blue(30px);
      }
      100% {
        transform: translateY(-60px);
        opacity: 0;
        filter: blue(80spx);
      }
    }
    #heat-2 {
      animation: steam 2s linear;
    }

    @keyframes steam {
      20% {
        transform: translateY(0);
        opacity: 1;
        filter: blue(30px);
      }
      80% {
        transform: translateY(-60px);
        opacity: 0;
        filter: blue(80spx);
      }
    }

    #light {
      animation: ${props => (props.isColor ? "light 0.5s ease-in" : "none")};
      animation-iteration-count: 2;
      opacity: ${props => (props.isColor ? "1 " : "0")};
    }

    @keyframes light {
      0% {
        opacity: 1;
      }
      10% {
        opacity: 0;
      }
      80% {
        opacity: 1;
      }
    }

    #lightbulb {
      fill: ${props => (props.isColor ? "" : "#fff")};
    }

    #inner-color {
      fill: ${props => (props.isColor ? "" : "#fff")};
      transition-delay: ${props => (props.isColor ? "1.5s" : "none")};
    }

    #clickhere {
      animation: ${props => (!props.isColor ? "click 0.8s ease" : "none")};
      animation-iteration-count: infinite;
    }

    @keyframes click {
      0%,
      100% {
        transform: rotate(0deg);
      }
      50% {
        transform: rotate(2deg);
      }
    }

    #string {
      animation: ${props => (props.isSwitch ? "switch 0.75s ease-in" : "none")};
    }

    @keyframes switch {
      0% {
        transform: none;
        transform-origin: center;
      }
      40% {
        transform: scaleY(1.3);
        transform-origin: center;
      }
      80% {
        transform: scaleY(1);
        transform-origin: center;
      }
    }
  }

  .over {
    position: absolute !important;
    left: 33.8%;
    width: 196px;
    top: 29.25%;
    height: 126px;
  }

  .button {
    position: absolute !important;
    left: 20%;
    top: 31%;
    z-index: 6;
    cursor: pointer;
    padding: 2rem;
    &:active,
    &:focus {
      outline: 0;
    }
  }

  @media (min-width: ${props => props.theme.sizes.mobile}) {
    display: block;
    margin-left: 0rem;
    margin: 3rem 0rem;
  }

  @media (min-width: ${props => props.theme.sizes.tablet}) {
    display: block;
    margin-left: 1rem !important;
    margin: 0rem 0rem;
  }
`

export const SteamWrapper = styled.div`
  position: absolute;
  visibility: visible;
  transform: translate(-50%, -50%);
  right: ${props => props.right};
  bottom: ${props => props.bottom};

  @media (min-width: ${props => props.theme.sizes.mobile}) {
    visibility: visible !important;
  }

  @media (min-width: ${props => props.theme.sizes.xmobile}) {
    visibility: hidden;
    right: ${props => props.right};
    bottom: ${props => props.bottom};
  }

  #steam {
    position: absolute;
    width: ${props => props.size};
    height: ${props => props.size};
    background-color: grey;
    border-radius: 50%;
    z-index: 4;
    opacity: 0;
  }

  .steam-one {
    animation: steam-one 8s ease-out infinite;
  }

  .steam-three {
    animation: steam-one 8s ease-out 1s infinite;
  }

  @keyframes steam-one {
    0% {
      transform: translateY(0) translateX(0) scale(0.25);
      opacity: 0.25;
    }
    100% {
      transform: translateY(-50px) translateX(-10px) scale(1);
      opacity: 0;
    }
  }

  .steam-two {
    animation: steam-two 4s ease-out 0.5s infinite;
  }

  .steam-four {
    animation: steam-two 4s ease-out 1.5s infinite;
  }

  @keyframes steam-two {
    0% {
      transform: translateY(0) translateX(0) scale(0.25);
      opacity: 0.25;
    }
    100% {
      transform: translateY(-50px) translateX(10px) scale(1);
      opacity: 0;
    }
  }
`

const Hero = ({ isColor, setColor }) => {
  const data = useStaticQuery(graphql`
    query {
      allFile(
        filter: { absolutePath: { regex: "/src/images/works/" } }
        sort: { fields: name }
      ) {
        nodes {
          id
          name
          publicURL
        }
      }
    }
  `)

  const { nodes } = data.allFile

  const works = [
    {
      name: "Packed",
      description: "packing web app for traveling",
      technologies: [
        "Typescript",
        "React/Redux",
        "Next.js",
        "Styled Components",
      ],
      code: "https://github.com/esthleej/packed",
      demo: "https://packed-two.vercel.app/",
      img: nodes[2].publicURL,
    },
    {
      name: "BookMe",
      description: "reading group management web app",
      technologies: [
        "Google Books API",
        "React Hooks/Context API",
        "Material UI",
        "Firebase",
      ],
      code: "https://github.com/esthleej/BookMe",
      demo: "https://bookme-10234.web.app/",
      img: nodes[1].publicURL,
    },
    {
      name: "Gatsby Starter Photo Gallery",
      description: "a simple responsive photo gallery",
      technologies: ["Contentful", "Gatsby.js", "React", "Styled Components"],
      code: "https://github.com/esthleej/gatsby-starter-photo-gallery",
      demo: "https://gatsby-starter-photo-gallery.netlify.app/",
      img: nodes[3].publicURL,
    },
    // {
    //   name: "Gatsby Starter Resturant",
    //   description: "a simple resturant starter",
    //   technologies: ["DatoCMS", "React", "Theme UI"],
    //   demo: "https://boomchicken.us",
    //   img: nodes[4].childImageSharp.fluid,
    // },
    {
      name: "Angela Lee Studio",
      description: "",
      technologies: ["Wordpress", "Gatsby.js", "React", "Styled Components"],
      demo: "https://www.angelaleestudio.com/",
      img: nodes[0].publicURL,
    },
  ]

  const { name, description, technologies, demo, code, img } = works[0]

  const [isSwitch, setSwitch] = useState(false)

  const [isWork, setWork] = useState(false)

  const [workInfo, setWorkInfo] = useState({
    name,
    description,
    technologies,
    demo,
    code,
    img,
  })

  const handleSwitch = () => {
    setSwitch(true)
    setTimeout(function() {
      setColor(!isColor)
      setWork(false)
    }, 760)
    setTimeout(function() {
      setSwitch(false)
    }, 760)
  }

  const workList = works.map(
    ({ name, description, technologies, demo, code, img }, id) => {
      return (
        <WorkItem
          key={`${name}${id}`}
          className="workItem"
          selected={workInfo.name === name}
          onClick={() => {
            setWorkInfo({ name, description, technologies, demo, code, img })
            setWork(true)
          }}
        >
          <span className="bullet">•</span>
          <Underline selected={workInfo.name === name} color="#bfd9ff">
            {name}
          </Underline>
        </WorkItem>
      )
    }
  )

  const worksInfo = () => {
    const techStack = workInfo.technologies.map(tech => {
      return <div className="tech">{tech}</div>
    })
    return (
      <WorkInfoContainer>
        <div className="title">
          {workInfo.name}
          <div className="source">
            {workInfo.code && (
              <a
                href={workInfo.code}
                target="_blank"
                rel="noopener noreferrer"
                aria-label="source code"
              >
                <CodeIcon />
              </a>
            )}
            {workInfo.demo && (
              <a
                href={workInfo.demo}
                target="_blank"
                rel="noopener noreferrer"
                aria-label={workInfo.name}
              >
                <ExternalLinkIcon />
              </a>
            )}
          </div>
        </div>
        <div className="description">{workInfo.description}</div>
        <div className="technologies">{techStack}</div>
      </WorkInfoContainer>
    )
  }

  return (
    <WorksContainer id="works">
      <h3>WORKS</h3>
      <div className="work-body">
        <WorkContent>
          <div>{workList}</div>
          {worksInfo()}
        </WorkContent>
        <WorkImg isColor={isColor} isSwitch={isSwitch} isWork={isWork}>
          <DeskImg className="main" />
          <div
            role="button"
            tabIndex={0}
            className="button"
            aria-label="light switch"
            onClick={handleSwitch}
            onKeyDown={handleSwitch}
          >
            &nbsp;
          </div>
          <img className="over" src={workInfo.img} alt={workInfo.name} />
          <SteamWrapper right="18%" bottom="37%" size="30px">
            <div id="steam" className="steam-one"></div>
            <div id="steam" className="steam-two"></div>
            <div id="steam" className="steam-three"></div>
            <div id="steam" className="steam-four"></div>
          </SteamWrapper>
        </WorkImg>
      </div>
    </WorksContainer>
  )
}

export default Hero
