import React, { useState } from "react"

import CheckIcon from "../../images/icons/check-circle.svg"
import CopyIcon from "../../images/icons/copy.svg"
import ContactImg from "../../images/contact.svg"

import { SteamWrapper } from "../works/Works"

import * as S from "./Contact.styled"

const Contact = ({ isColor }) => {
  const email = "estherleedev@gmail.com"
  const title = "Contact"

  const [copied, setCopied] = useState(false)

  const copyToClipboard = () => {
    const dummy = document.createElement("input")
    document.body.appendChild(dummy)
    dummy.setAttribute("value", email)
    dummy.select()
    document.execCommand("copy")
    document.body.removeChild(dummy)

    setCopied(true)
    setTimeout(function() {
      setCopied(false)
    }, 500)
  }

  return (
    <S.ContactContainer id="contact">
      <h3 className="mobile-title">{title}</h3>
      <S.ContentBody isColor={isColor}>
        <S.ContactImgContainer>
          <ContactImg />
          <SteamWrapper right="30%" bottom="68%" size="20px">
            <div id="steam" className="steam-one"></div>
            <div id="steam" className="steam-two"></div>
            <div id="steam" className="steam-three"></div>
            <div id="steam" className="steam-four"></div>
          </SteamWrapper>
        </S.ContactImgContainer>
        <S.Body>
          <S.BodyText>
            <h3 className="desktop-title">{title}</h3>
            <p>
              I love working with people especially on anything related to the
              web. For inquiries about building a website or working together,
              please email me!
            </p>
            <S.Email isColor={isColor}>
              Email:
              <div
                onClick={copyToClipboard}
                role="button"
                tabIndex="-1"
                aria-label="copy email icon"
                onKeyDown={copyToClipboard}
              >
                {copied ? <CheckIcon /> : <CopyIcon />}
              </div>
              {copied ? "copied" : email}
            </S.Email>
          </S.BodyText>
          <S.EmailButton isColor={isColor} href={`mailto:${email}`}>
            contact me
          </S.EmailButton>
        </S.Body>
      </S.ContentBody>
    </S.ContactContainer>
  )
}
export default Contact
