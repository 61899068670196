import React, { useState } from "react"

import Hero from "../components/hero/Hero"
import Works from "../components/works/Works"
import Contact from "../components/contact/Contact"

import styled from "styled-components"
import SEO from "../components/seo/SEO"

const IndexContainer = styled.div`
  padding-top: 64px;
  background-color: #fffbdb;
`

const IndexPage = () => {
  const [isColor, setColor] = useState(false)

  return (
    <IndexContainer>
      <SEO />
      <Hero isColor={isColor} />
      <Works isColor={isColor} setColor={setColor} />
      <Contact isColor={isColor} />
    </IndexContainer>
  )
}

export default IndexPage
